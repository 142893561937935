<template>
  <div>
    <v-container>
      <v-layout row wrap justify-space-between class="mb-2">
        <v-flex xs6 sm4 md4 lg4 class="mb-1">
          <v-btn
            :color="$store.state.secondaryColor"
            outlined
            small
            @click="goBack"
          >
            <v-icon small left>mdi-arrow-left</v-icon>
            <span class="caption">{{ $t("back") }}</span>
          </v-btn>
        </v-flex>
      </v-layout>
      <v-layout row wrap justify-space-between class="mb-2">
        <v-flex xs12 sm4 md4 lg4 class="py-1 px-1">
          <v-select
            v-if="!currentBusiness"
            dense
            solo
            class="caption"
            :label="$t('select business')"
            item-text="businessName"
            item-value="id"
            :items="ownersBusinesses"
            v-model="selectedBusiness"
            @change="onSelectBusiness"
          ></v-select>
        </v-flex>

        <v-flex xs12 sm4 md4 lg4 class="text-right py-1 px-1">
          <v-select
            dense
            solo
            class="caption"
            :label="$t('select year')"
            item-text="businessName"
            item-value="id"
            :items="years"
            v-model="selectedYear"
            @change="onSelectYear"
          ></v-select>
        </v-flex>
        <v-spacer></v-spacer>

        <v-flex xs12 sm2 md2 lg2 class="text-right py-1 pr-1">
          <v-btn dark small class="gradients" @click="printPage()">
            <v-icon small left>mdi-download</v-icon>
            {{ $t("download") }}
          </v-btn>
        </v-flex>
      </v-layout>

      <!-- ##################################### -->

      <div class="body" id="print">
        <div class="container">
          <div class="row right">
            <div class="column-quarter left">&nbsp;</div>
            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter">
              <span class="bold-text title">{{ title }}</span>
            </div>
          </div>
          <!-- business details -->

          <div class="row">
            <div class="column-half left">
              <div>
                <span class="bold-text"
                  >{{ $t("business name") }} :
                  <span class="light-text">{{ businessName }}</span></span
                ><br />
              </div>
              <div>
                <span class="bold-text">
                  <span class="light-text">{{ statementPeriod }}</span></span
                ><br />
              </div>
            </div>

            <div class="column-half left">&nbsp;</div>
          </div>

          <div class="row">
            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter right">
              <span class="bold-text black--text">{{ currencySymbol }}</span>
            </div>

            <div class="column-quarter right">
              <span class="bold-text black--text">{{ currencySymbol }}</span>
            </div>
          </div>

          <div class="row">
            <div class="column-quarter left">
              <span class="bold-text black--text">{{ $t("sales") }}</span>
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter right">
              <span class="bold-text text-right">
                {{ currencyFormat(totalSales) }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="column-quarter left">
              <span class="bold-text black--text">
                {{ $t("opening stock as of") }} ( dd/MM/YYYY)</span
              >
            </div>

            <div class="column-quarter right">
              <span class="bold-text text-right">
                {{ currencyFormat(openingStock) }}
              </span>
            </div>
            <div class="column-quarter">&nbsp;</div>
          </div>
          <div class="row">
            <div class="column-quarter left">
              <span class="bold-text black--text">{{
                $t("add purchases")
              }}</span>
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter">&nbsp;</div>
          </div>

          <div class="row">
            <div class="column-quarter left">
              <span class="bold-text black--text">{{
                $t("total purchases")
              }}</span>
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter right">
              <span class="bold-text text-right">
                {{ currencyFormat(totalPurchase) }}
              </span>
            </div>
          </div>
          <div class="row">
            <div class="column-quarter left">
              <span class="bold-text black--text">
                {{ $t("less closing inventory as at") }} (dd/MM/YYYY)</span
              >
            </div>

            <div class="column-quarter right">
              <span class="bold-text text-right">
                {{ currencyFormat(closingStock) }}
              </span>
            </div>
            <div class="column-quarter">&nbsp;</div>
          </div>
          <!-- cost of goods sold -->

          <div class="row">
            <div class="column-quarter left">
              <span class="bold-text black--text">
                {{ $t("cost of goods sold") }}</span
              >
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter right">
              <span class="bold-text text-right">
                {{ currencyFormat(costOfGoods) }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="column-quarter left">
              <span class="bold-text black--text">
                {{ $t("add revenues") }}</span
              >
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter">&nbsp;</div>
          </div>

          <div class="row" v-for="item in revenues" :key="item.accountName">
            <div class="column-quarter left">
              <span class="font-weight-light black--text">{{
                item.accountName
              }}</span>
            </div>
            <div class="column-quarter right">
              <span class="bold-text text-right">
                {{ currencyFormat(item.amount) }}
              </span>
            </div>
            <div class="column-quarter">&nbsp;</div>
          </div>

          <div class="row">
            <div class="column-quarter left">
              <span class="bold-text black--text">{{
                $t("total revenues")
              }}</span>
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter right">
              <span class="bold-text text-right">
                {{ currencyFormat(totalRevenue) }}
              </span>
            </div>
          </div>
          <!-- gross profit -->

          <div v-if="grossProfit >= 0" class="row success lighten-5">
            <div class="column-quarter left">
              <span class="bold-text black--text"
                >{{ $t("back") }}Gross profit</span
              >
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter right">
              <span class="bold-text text-right success--text text--darken-2">
                {{ currencyFormat(grossProfit) }}
              </span>
            </div>
          </div>
          <div v-else class="row red lighten-5">
            <div class="column-quarter left">
              <span class="bold-text black--text">{{
                $t("gross profit")
              }}</span>
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter right">
              <span class="bold-text text-right red--text text--darken-2">
                {{ currencyFormat(grossProfit) }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="column-quarter left">
              <span class="bold-text black--text">{{
                $t("less expenses")
              }}</span>
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter">&nbsp;</div>
          </div>

          <div class="row" v-for="(item, i) in expenses" :key="i">
            <div class="column-quarter left">
              <span class="font-weight-light black--text">{{
                item.accountName
              }}</span>
            </div>
            <div class="column-quarter right">
              <span class="bold-text text-right">
                {{ currencyFormat(item.amount) }}
              </span>
            </div>
            <div class="column-quarter">&nbsp;</div>
          </div>

          <div class="row">
            <div class="column-quarter left">
              <span class="bold-text black--text">{{
                $t("total expenses")
              }}</span>
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter right">
              <span class="bold-text text-right">
                {{ currencyFormat(totalExpense) }}
              </span>
            </div>
          </div>

          <!-- net profit -->
          <div v-if="netProfit >= 0" class="row success lighten-5">
            <div class="column-quarter left">
              <span class="bold-text black--text"
                >{{ $t("net profit") }}(EBIT)</span
              >
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter right">
              <span class="bold-text text-right success--text text--darken-2">
                {{ currencyFormat(netProfit) }}
              </span>
            </div>
          </div>
          <div v-else class="row red lighten-5">
            <div class="column-quarter left">
              <span class="bold-text black--text"
                >{{ $t("net profit") }}(EBIT)</span
              >
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter right">
              <span class="bold-text text-right red--text text--darken-2">
                {{ currencyFormat(netProfit) }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="column-quarter left">
              <span class="bold-text black--text">{{ $t("taxation") }}</span>
            </div>

            <div class="column-quarter right">
              <span class="bold-text text-right"> {{ taxation }}% </span>
            </div>
            <div class="column-quarter right">
              <span class="bold-text text-right">
                {{ currencyFormat(totalTaxes) }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="column-quarter left">
              <span class="bold-text black--text">{{ $t("dividends") }}</span>
            </div>

            <div class="column-quarter right">
              <span class="bold-text text-right"> {{ dividends }}% </span>
            </div>
            <div class="column-quarter right">
              <span class="bold-text text-right">
                {{ currencyFormat(totalDividends) }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="column-quarter left">
              <span class="bold-text black--text">{{
                $t("net profit margin")
              }}</span>
            </div>

            <div class="column-quarter right">
              <span class="bold-text text-right"> {{ netProfitMargin }}% </span>
            </div>
            <div class="column-quarter">&nbsp;</div>
          </div>

          <!-- Retained earnings -->
          <div v-if="profitLoss" class="row success lighten-5">
            <div class="column-quarter left">
              <span class="bold-text black--text">
                {{ $t("retained earnings") }} (EAIT)</span
              >
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter right">
              <span class="bold-text text-right success--text text--darken-2">
                {{ currencyFormat(retainedEarnings) }}
              </span>
            </div>
          </div>
          <div v-else class="row red lighten-5">
            <div class="column-quarter left">
              <span class="bold-text black--text">
                {{ $t("retained earnings") }} (EAIT)</span
              >
            </div>

            <div class="column-quarter">&nbsp;</div>
            <div class="column-quarter right">
              <span class="bold-text text-right red--text text--darken-2">
                {{ currencyFormat(retainedEarnings) }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="caption mt-4">
        <p class="font-weight-bold">
          <u>{{ $t("formulas") }}</u>
        </p>
        <p>
          {{
            $t(
              "Cost of Goods Sold = Beginning Inventory+P−Ending Inventory where: P=Purchases during the period"
            )
          }}
        </p>
        <p>{{ $t("Gross Profit = Sales – Cost of Goods Sold") }}</p>
        <p>{{ $t("Net profit = Gross profit - expenses") }}</p>
        <p>{{ $t("Retained earnings = Net profit - taxation") }}</p>
        <p>{{ $t("Net Profit margin = Net Profit ⁄ Total revenue x 100") }}</p>
      </div>
      <!-- ##################################### -->

      <v-snackbar
        v-model="snackError"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span> {{ $t("please also select a year") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="red darken-3"
            @click="snackError = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="snackStatementIncomplete"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span>{{
          $t("statement is incomplete. Select a business and year")
        }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="red darken-3"
            @click="snackStatementIncomplete = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar
        v-model="snackInfo"
        top
        center
        color="info lighten-5 info--text text--darken-3"
        :timeout="8000"
      >
        <span>{{ $t("calculating...") }}</span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="info darken-3"
            @click="snackInfo = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="snackErrorBusiness"
        top
        center
        color="red lighten-5 error--text text--darken-3"
        :timeout="4000"
      >
        <span> {{ $t("please also select a business") }} </span>
        <template v-slot:action="{ attrs }">
          <v-btn
            v-bind="attrs"
            icon
            color="red darken-3"
            @click="snackErrorBusiness = false"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
import { printer } from "@/plugins/functions";
import db from "@/plugins/fb";
import { getYear, format, endOfYear } from "date-fns";
export default {
  data: () => ({
    expenses: [],
    revenues: [],
    totalExpense: 0,
    totalPurchase: 0,
    totalRevenue: 0,
    costOfGoods: 0,
    grossProfit: 0,
    netProfit: 0,
    taxation: 0,
    totalTaxes: 0,
    dividends: 0,
    totalDividends: 0,
    netProfitMargin: 0,
    retainedEarnings: 0,
    products: [],
    purchases: [],
    totalSales: 0,
    openingStock: 0,
    closingStock: 0,
    years: [2020, 2021],
    businessName: "",
    businessEmail: "",
    statementPeriod: "",
    selectedBusiness: "",
    selectedYear: "",
    generatedOn: "",
    calculated: false,
    snackError: false,
    snackStatementIncomplete: false,
    snackErrorBusiness: false,
    snackInfo: false,
    profitLoss: true,
  }),

  created() {
    this.getBusinessName();
  },

  computed: {
    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    currencySymbol() {
      return this.$store.state.currency.symbol;
    },
    currentBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },
    selectedBusinessName() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.businessName;
    },
    title() {
      return "income statement";
    },

    selectedBusinessEmail() {
      let selected = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      return selected.email;
    },
  },

  methods: {
    printer,
    expense() {
      if (this.selectedBusiness) {
        this.expenses = [];
        db.collection("accounts")
          .where("businessId", "==", this.selectedBusiness)
          .where("accountType", "==", "expenses")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.expenses.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            //filter by year
            this.expenses.filter((item) => item.dateTime == this.selectedYear);
            this.totalExpenses(this.expenses);
          });
      }
    },

    revenue() {
      if (this.selectedBusiness) {
        this.revenues = [];
        db.collection("accounts")
          .where("businessId", "==", this.selectedBusiness)
          .where("accountType", "==", "revenues")
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.revenues.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.revenues.filter((item) => item.dateTime == this.selectedYear);
            this.totalRevenues(this.revenues);
          });
      }
    },

    getAccountingSettings() {
      this.currentUser = this.$store.state.currentUser;
      if (this.selectedBusiness) {
        db.collection("configurations")
          .doc(this.currentUser)
          .get()
          .then((snaps) => {
            if (snaps.exists) {
              this.taxation = snaps.data().taxation;
              this.dividends = snaps.data().dividends;
              this.snackLoading = false;
            } else {
              this.taxation = 0;
              this.dividends = 0;
            }
          });
      }
    },

    purchase() {
      if (this.selectedBusiness) {
        this.purchases = [];
        db.collection("purchaseOrders")
          .where("businessId", "==", this.selectedBusiness)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.purchases.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.purchases.filter(
              (item) => getYear(item.dateTime.toDate()) == this.selectedYear
            );
            this.totalPurchases(this.purchases);
          });
      }
    },

    getBusinessName() {
      if (this.selected) {
        let all = this.$store.getters.getBusinesses;

        let currentBusiness = all.find((x) => x.id == this.selected);
        this.businessName = currentBusiness.businessName;
      }
    },
    // if on general dashboard
    onSelectBusiness() {
      if (this.selectedYear) {
        this.snackError = false;
        this.snackErrorBusiness = false;
        this.snackInfo = true;
        //populate income statement title
        this.businessName = this.selectedBusinessName;
        this.businessEmail = this.selectedBusinessEmail;

        this.setReportDates();
        //call expenses function

        this.getAccountingSettings();
        this.expense();
        this.revenue();
        this.getSales();
        this.getStock();
        this.purchase();
      } else {
        //populate income statement title
        this.businessName = this.selectedBusinessName;
        this.businessEmail = this.selectedBusinessEmail;
        this.snackError = true;
      }
    },
    //get the stock for the business
    getStock() {
      this.products = [];
      db.collection("products")
        .where("businessID", "==", this.selectedBusiness)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.products.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.totalsStock(this.products);
        });
    },
    setReportDates() {
      //document title
      this.generatedOn = this.today;
      this.statementPeriod =
        "For Year Ending " + format(endOfYear(new Date()), "yyyy-MM-dd");
    },

    getIncomeStatement() {},

    //function called when month is changed
    onSelectYear() {
      if (this.selectedBusiness) {
        this.snackError = false;
        this.snackErrorBusiness = false;
        this.snackInfo = true;
        //call expenses function

        this.getAccountingSettings();
        this.expense();
        this.revenue();
        this.getSales();
        this.getStock();
        this.setReportDates();
        this.purchase();
      } else {
        this.snackErrorBusiness = true;
      }
      //pass the selected month to a filter function
    },
    getSales() {
      let sales = [];
      db.collection("transactions")
        .where("businessId", "==", this.selectedBusiness)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            sales.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          sales.filter(
            (item) => getYear(item.dateTime.toDate()) == this.selectedYear
          );
          this.totals(sales);
        });
    },
    //gross profit
    calculateGrossProfit() {
      this.grossProfit = this.totalSales - this.costOfGoods;

      this.calculateNetProfit();
    },
    //calculate cost of goods
    calculateCostOfGoods() {
      this.costOfGoods =
        this.openingStock + this.totalPurchase - this.closingStock;

      this.calculateGrossProfit();
    },
    //net profit
    calculateNetProfit() {
      let money = parseInt(this.grossProfit) + parseInt(this.totalRevenue);

      this.netProfit = money - this.totalExpense;
      this.calculateTaxation();
    },
    //calculate taxation
    calculateTaxation() {
      this.totalTaxes = (this.taxation / 100) * this.netProfit;

      this.calculateDividends();
    },
    //calculate dividends
    calculateDividends() {
      this.totalDividends = (this.dividends / 100) * this.netProfit;
      this.calculateNetProfitMargin();
    },

    //calculate net profit margin
    calculateNetProfitMargin() {
      this.netProfitMargin = (this.netProfit / this.totalRevenue) * 100;
      this.calculateRetainedEarnings();
    },

    calculateRetainedEarnings() {
      this.retainedEarnings =
        this.netProfit - this.totalTaxes - this.totalDividends;

      // show red or green depending on if retained earnings is positive or negative
      if (Math.sign(this.retainedEarnings) == 1) {
        this.profitLoss = true;
      } else {
        this.profitLoss = false;
      }
      this.calculated = true;
      this.snackInfo = false;
    },
    //calculate total sales
    totals(arr) {
      let allAmounts = arr.map((item) => Number(item.amount));

      let sumAmounts =
        allAmounts.length > 1
          ? allAmounts.reduce((prev, next) => prev + next)
          : allAmounts[0] != null
          ? allAmounts[0]
          : 0;
      //.toLocaleString('en') add commas after 3 digits
      this.totalSales = sumAmounts;
    },
    //find total of expenses
    totalExpenses(arr) {
      let allAmounts = arr.map((item) => Number(item.amount));

      let sumAmounts =
        allAmounts.length > 1
          ? allAmounts.reduce((prev, next) => prev + next)
          : allAmounts[0] != null
          ? allAmounts[0]
          : 0;
      //.toLocaleString('en') add commas after 3 digits
      this.totalExpense = sumAmounts;
    },

    //find total of purchases
    totalPurchases(arr) {
      let allAmounts = arr.map((item) => Number(item.total));

      let sumAmounts =
        allAmounts.length > 1
          ? allAmounts.reduce((prev, next) => prev + next)
          : allAmounts[0] != null
          ? allAmounts[0]
          : 0;
      //.toLocaleString('en') add commas after 3 digits
      this.totalPurchase = sumAmounts;

      this.calculateCostOfGoods();
    },

    //find total of expenses
    totalRevenues(arr) {
      let allAmounts = arr.map((item) => Number(item.amount));

      let sumAmounts =
        allAmounts.length > 1
          ? allAmounts.reduce((prev, next) => prev + next)
          : allAmounts[0] != null
          ? allAmounts[0]
          : 0;
      //.toLocaleString('en') add commas after 3 digits
      this.totalRevenue = sumAmounts;
    },

    //calculate total value of stock
    totalsStock(arr) {
      let buying = arr.map((item) => Number(item.price.buying));
      let selling = arr.map((item) => Number(item.price.selling));
      let ostock = arr.map((item) => Number(item.quantity.openingStock));
      let cstock = arr.map((item) => Number(item.quantity.inStock));

      var os = 0;
      var cs = 0;
      for (let i = 0; i < buying.length; i++) {
        os += buying[i] * ostock[i];
        cs += selling[i] * cstock[i];
      }

      //.toLocaleString('en') add commas after 3 digits
      this.openingStock = os;
      this.closingStock = cs;
    },

    currencyFormat(val) {
      return this.$store.state.currency.symbol + " " + Number(val).toFixed(2);
    },
    printPage() {
      if (this.calculated) {
        this.printer("print", "Income statement");
      } else {
        this.snackStatementIncomplete = true;
      }
    },

    goBack() {
      this.$router.push("/reports");
    },
  },
};
</script>

<style scoped>
.white-hr {
  border: 1px dashed white;
  background-color: white;
}
.black-hr {
  border-top: 1px solid #000000;
  height: 1px;
  border-radius: 5px;
}
.grey-hr {
  border-top: 1px solid #cecece;
  height: 1px;
  border-radius: 5px;
}
.black-background {
  background-color: #000000;
}
.gradients {
  background-image: linear-gradient(to bottom right, #0579a3, #005270);

  /* background-image: linear-gradient(to bottom right, #072033,#103c5e); */
}
.white-text {
  color: white;
}

.center {
  text-align-last: center;
}

.center {
  text-align-last: center;
}
.left {
  text-align-last: left;
}

.right {
  text-align-last: right;
}
/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.title {
  color: #ed6d1e;
}
.success {
  color: black;
  background-color: #dcffd6;
}

.red {
  color: black;
  background-color: #ffebee;
}

.body {
  background-color: #e0e0e0;
}
.body .container {
  background: black (0, 0, 0); /* Fallback color */
  color: grey (126, 126, 126);
  font-size: 15px;
  background-color: white;
}
.bold-text {
  font-weight: bolder;
}
.light-text {
  font-weight: lighter;
}
.column {
  float: left;
  width: 100%;
  padding: 10px;
}

.column-half {
  float: left;
  width: 50%;
  padding: 10px;
}

.column-third {
  float: left;
  width: 33.33%;
  padding: 10px;
}

.column-two-third {
  float: left;
  width: 66.66%;
  padding: 10px;
}

.column-quarter {
  float: left;
  width: 25%;
  padding: 10px;
}

.column-three-quarter {
  float: left;
  width: 75%;
  padding: 10px;
}
.column-small {
  float: left;
  width: 15%;
  padding: 10px;
}
.column-extra-large {
  float: left;
  width: 85%;
  padding: 10px;
}
.column-forty-percent {
  float: left;
  width: 40%;
  padding: 10px;
}
.column-sixty-percent {
  float: left;
  width: 60%;
  padding: 10px;
}
.column-twenty-percent {
  float: left;
  width: 20%;
  padding: 10px;
}
.button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 5px 15px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;

  width: 100%;
}
#summary {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#summary td,
#summary th {
  border: 1px solid #ddd;
  padding: 8px;
}

#summary tr:nth-child(even) {
  background-color: #f2f2f2;
}

#summary tr:hover {
  background-color: #ddd;
}

#summary th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #ef6c00;
  color: white;
}
</style>
